import React, { useState } from 'react'
import './contact.css'
import { FaFacebook } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { CiMail } from "react-icons/ci";

const Contact = () => {

  const [contact, setContact] = useState(false)

  function toggle () {
    setContact((prev) => {
      return !prev
    })
  }

  return (
    <section className='contactt' id='contact'>
      <div className='wrapper'>
        <button 
          title='Email'
          className='contact-btn'
          onClick={ () => { setContact(true) } }
          ><CiMail />
        </button>
        <button 
          title='Facebook'
          className='contact-btn'
          onClick={ () => { setContact(true) } }
        ><FaFacebook />
        </button>
        <button 
          title='Github'
          className='contact-btn'
          onClick={ () => { setContact(true) } }
        ><FaGithub />
        </button>
        <button 
          title='Linkedin'
          className='contact-btn'
          onClick={ () => { setContact(true) } }
        ><FaLinkedin />
        </button>
      </div>
      <div className='modal-container'>
        { contact && <div className='modal'>
          <button 
            onClick={ () => { setContact(false) } } 
            className='close-btn'
          >X
          </button>
          <p>Mail: igweoscar001@gmail.com</p>
          <p>Tel: +2348101898896</p>
          <p>GitHub: <a target='_blank' href='https://github.com/oscar4dev'>oscar4dev</a></p>
          <p>Linkedin: </p>
        </div> }
      </div>
    </section>
  )
}

export default Contact