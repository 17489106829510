import React from 'react'
import './projects.css'
import projectData from '../data'
import Title from './Title'

const Projects = () => {
  return (
    <section className='projects' id='projects'>
      <Title text='Projects' />
      <p className='title'>Some of the projects I'm currently working on...</p>
      <div className='wrapper'>
        { projectData.map((project) => {
          return <div key={project.id} className='projects-tile'>
            <div className='top'>
              <img src={project.img} className='main-img' />
            </div>
            <div className='bottom'>
              <p>
                <span 
                  className='project-name'
                  >Project Name: 
                </span>
                {project.name}
              </p>
              <p>
                <span 
                  className='desc'
                  >Description:
                </span> 
                {project.desc}
              </p>
              <p>
                <span className='tech'>
                  Technologies:
                </span>
                { project.technologies }
              </p>
              <div className='link'>
                <a 
                  className='demo'
                  target='_blank' 
                  href={`${project.site}`}
                  >Live Demo
                </a>
                <a 
                  className='source'
                  target='_blank' 
                  href={`${project.code}`}
                  >Project Code
                </a>
              </div>
            </div>
          </div>
        }) }
      </div>
    </section>
  )
}

export default Projects