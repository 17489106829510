import React from 'react'
import './about.css'
import { IoMdHappy } from "react-icons/io";
import { FaReact } from "react-icons/fa";
import { AiOutlineGlobal } from "react-icons/ai";
import Title from './Title';
import { FaCertificate } from "react-icons/fa6";

const About = () => {
  return (
   <section className='about' id='about'>
    <Title text='Who I am' />
    <div className='wrapper'>
      <p>
        Hello! 👋 I'm a dedicated front-end developer 
        currently enrolled at Scrimba, where I've invested 12 
        months mastering the essentials of front-end 
        development, with a particular emphasis on 
        <span> React. </span>
      </p>
      <div className='certificate-container'>
        <a href='https://scrimba.com/certificate/uwqg4kur/gfrontend'target='_blank' >
          <FaCertificate className='icon' />View My Scrimba Certificate
        </a>
      </div>
    </div>
    <div className='wrapper'>
      <div className='title-container'>
        <FaReact className='react-icon'/>
        <p className='title'>React Specialist</p>
      </div>
      <p> 
        My special interest in 
        React extends my capabilities in developing dynamic and 
        efficient web applications.
      </p>
    </div>
    <div className='wrapper'>
      <div className='title-container'>
        <IoMdHappy className='smile-icon'/>
        <p className='title'>User-Friendly Solutions</p>
      </div>
      <p>
        I thrive on creating tools 
        that prioritize user-friendliness, simplicity, and a 
        delightful experience.
      </p>
    </div>
    <div className='wrapper'>
      <div  className='title-container'>
        <AiOutlineGlobal className='globe-icon'/>
        <p className='title'>Global Collaboration</p>
      </div>
      <p>
        Throughout my learning journey, I've collaborated with 
        large specialized teams across diverse time zones, 
        refining my working style to prioritize flexibility, 
        clarity, and effective collaboration.
      </p>
    </div>
   </section>
  )
}

export default About