import React from 'react'
import './technologies.css'
import Title from './Title'
import { IoLogoJavascript } from "react-icons/io";
import { FaReact } from "react-icons/fa";
import { FaFigma } from "react-icons/fa";
import { FaGithub } from "react-icons/fa";

const Technologies = () => {
  return (
    <section className='technologies' id='technologies'>
      <Title text='Technologies' />
      <div className='wrapper'>
        <span title='JavaScript'><IoLogoJavascript className='icon'/></span>
        <span title='React'><FaReact className='icon' /></span>
        <span title='Figma'><FaFigma className='icon' /></span>
        <span title='GitHub'><FaGithub className='icon' /></span>
      </div>
    </section>
  )
}

export default Technologies