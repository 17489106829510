import Image from './assets/screenshot1.PNG'
import RestaurantImg from './assets/restaurant-app.PNG'
import carlinkImg from './assets/Capture-carlink.PNG'
import natureImg from './assets/nature5.jpg'

const projectData = [
   {
      img: RestaurantImg,
      name: ' Restaurant Website.',
      desc: ' Fully responsive Restaurant website using React Router Library.',
      site: 'https://resto.strawberryeat.com/',
      code: 'https://github.com/oscar4dev/strawberryApp',
      technologies: ' React Router Dom.',
      id: 1
   },
   {
      img: Image,
      name: ' X Clone.',
      desc: ' X clone called Discuss, mimicking some of the functionalities of real X.',
      site: 'https://xclone.strawberryeat.com/',
      code: 'https://github.com/oscar4dev/xCloneProject',
      technologies: ' JavaScript, Css, Html.',
      id: 2
   },
  
   {
      img: carlinkImg,
      name: ' Carlink Autos.',
      desc: ' An app that identifies suitable cars for customers when the click a button.',
      site: 'https://carlinkautos.strawberryeat.com/',
      code: 'https://github.com/oscar4dev/carLink',
      id: 3,
      technologies: ' JavaScript, Css, Html.',
   },
   {
      img: natureImg,
      name: ' project4',
      desc: ' N/A',
      site: 'live website',
      technologies: ' N/A',
      code: 'source code',
      id: 4
   },
]

export default projectData