import React from 'react'
import './footer.css'

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='wrapper'>
        <p>Copyright &copy; 2024. All rights reserved.</p>
      </div>
    </footer>
  )
}

export default Footer