import React, { useState } from 'react'
import './header.css'
import { MdOutlineLightMode, MdOutlineNumbers } from "react-icons/md";
import { MdDarkMode } from "react-icons/md";
import { IoMdMenu } from "react-icons/io";
import { IoMdClose } from "react-icons/io";


const Header = () => {

  const [sidebar, setSideBar] = useState(false)

  return (
    <div>
      <header>
        <div className=''>
          <ul>
            <li>
              <a href='#home' className='title'>Oscar Uche</a>
            </li>
          </ul>
        </div>
        <div className='hide'>
          <ul>
            <li>
              <a href='#about'>About</a>
            </li>
            <li>
              <a href='#projects'>Projects</a>
            </li>
            <li>
              <a href='#technologies'>Technologies</a>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li className='hide'>
              <a href='#contact'>Contact</a>
            </li>
            <IoMdMenu 
              className='menu-btn'
              onClick={ () => { setSideBar(true) } }
            />
          </ul>
        </div>
      </header>
      <sidebar>
        {
          sidebar && <div className='sidebar'>
            <IoMdClose 
              className='close-btn'
              onClick={ () => { setSideBar(false) } }
            />
            <ul>
              <li><a 
                href='#home' 
                onClick={ () => { setSideBar(false) } } 
                >Home</a>
              </li>
              <li>
                <a 
                  href='#about'
                  onClick={ () => { setSideBar(false) } } 
                  >About
                </a>
              </li>
              <li>
                  <a 
                    href='#projects'
                    onClick={ () => { setSideBar(false) } } 
                    >Projects
                  </a>
              </li>
              <li>
                  <a 
                    href='#technologies'
                    onClick={ () => { setSideBar(false) } } 
                    >Technologies
                  </a>
              </li>
              <li>
                <a 
                  href='#contact'
                  onClick={ () => { setSideBar(false) } } 
                  >Contact
                </a>
              </li>
            </ul>
          </div>
        }
      </sidebar>
    </div>
  )
}

export default Header