import React from 'react'
import './home.css'
import { IoMdPerson } from "react-icons/io";
import Title from './Title'
import { FaEnvelope } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

const Home = () => {
  return (
    <section className='home' id='home'>
      <div className='wrapper right'>
        <p className='title'>Oscar Uche</p>
        <Title text='Frontend Developer' />
        <div className='link-container'>
          <a href='#'><FaEnvelope className='envelop'/> Email</a>
          <a href='#'><FaLinkedin className='linkedin'/> LinkedIn</a>
        </div>
        <p>
          I am a frontend developer with special interest in
          <span> React. </span> I try to keep up with security and best 
          practices, and I am always looking for new things
          to learn. 
        </p>
      </div>
      <div className='wrapper left'>
        <IoMdPerson className='avatar' />
      </div>
    </section>
  )
}

export default Home