import './App.css';
import React from 'react'
import { Home, About, Contact, Footer, Header, Projects, Technologies } from "./components";

const App = () => {
  return (
    <div>
      <Header/>
      <Home />
      <About />
      <Projects />
      <Technologies />
      <Contact />
      <Footer />
    </div>
  )
}

export default App