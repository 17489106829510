import React from 'react'
import './title.css'

const Title = ({ text }) => {
  return (
   <div className='component-title'>
      <div className='design' />
      <h1>{text}</h1>
   </div>
  )
}

export default Title